import React from "react";

import Program from "../../program";

const ProgramEdit = ({params}) => {
    const i = params.i;
    const name = params.name;

    return <Program name={name} projectIndex={i} />;
};

export default ProgramEdit;
